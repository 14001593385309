export default {
    BackendUrl: process.env.VUE_APP_BACKEND_API_BASE_URL,
    DocsUrl: process.env.VUE_APP_DOCS_URL,
    APIClientPython: "https://github.com/cvr-dev/cvr.dev-python",
    APIClientGo: "https://github.com/cvr-dev/cvr.dev-go",
    PlausibleDataDomain: process.env.VUE_APP_PLAUSIBLE_DATA_DOMAIN,
    VirkTermsAndConditionsUrl: "https://datacvr.virk.dk/artikel/vilkaar-og-betingelser#accordion-5-button",

    // Starter
    ProductStarterPriceMonthly: parseFloat(process.env.VUE_APP_PRODUCT_STARTER_PRICE_MONTHLY),
    ProductStarterPriceMonthlyId: process.env.VUE_APP_PRODUCT_STARTER_PRICE_MONTHLY_ID,
    ProductStarterPriceYearlyPerMonth: parseFloat(process.env.VUE_APP_PRODUCT_STARTER_PRICE_YEARLY_PER_MONTH),
    ProductsStarterPriceYearlyId: process.env.VUE_APP_PRODUCT_STARTER_PRICE_YEARLY_ID,
    ProductsStarterRequestsPerMonth: parseInt(process.env.VUE_APP_PRODUCT_STARTER_REQUESTS_PER_MONTH),
    ProductsStarterRequestsPerSecond: parseInt(process.env.VUE_APP_PRODUCT_STARTER_REQUESTS_PER_SECOND),

    // Business
    ProductBusinessPriceMonthly: parseFloat(process.env.VUE_APP_PRODUCT_BUSINESS_PRICE_MONTHLY),
    ProductBusinessPriceMonthlyId: process.env.VUE_APP_PRODUCT_BUSINESS_PRICE_MONTHLY_ID,
    ProductBusinessPriceYearlyPerMonth: parseFloat(process.env.VUE_APP_PRODUCT_BUSINESS_PRICE_YEARLY_PER_MONTH),
    ProductsBusinessPriceYearlyId: process.env.VUE_APP_PRODUCT_BUSINESS_PRICE_YEARLY_ID,
    ProductsBusinessRequestsPerMonth: parseInt(process.env.VUE_APP_PRODUCT_BUSINESS_REQUESTS_PER_MONTH),
    ProductsBusinessRequestsPerSecond: parseInt(process.env.VUE_APP_PRODUCT_BUSINESS_REQUESTS_PER_SECOND),

    // Business Pro
    ProductBusinessProPriceMonthly: parseFloat(process.env.VUE_APP_PRODUCT_BUSINESS_PRO_PRICE_MONTHLY),
    ProductBusinessProPriceMonthlyId: process.env.VUE_APP_PRODUCT_BUSINESS_PRO_PRICE_MONTHLY_ID,
    ProductBusinessProPriceYearlyPerMonth: parseFloat(process.env.VUE_APP_PRODUCT_BUSINESS_PRO_PRICE_YEARLY_PER_MONTH),
    ProductsBusinessProPriceYearlyId: process.env.VUE_APP_PRODUCT_BUSINESS_PRO_PRICE_YEARLY_ID,
    ProductsBusinessProRequestsPerMonth: parseInt(process.env.VUE_APP_PRODUCT_BUSINESS_PRO_REQUESTS_PER_MONTH),
    ProductsBusinessProRequestsPerSecond: parseInt(process.env.VUE_APP_PRODUCT_BUSINESS_PRO_REQUESTS_PER_SECOND),
}
<template>
  <v-app v-show="isCognitoReady">
    <TheHeader />
    <v-main>
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-fade-transition hide-on-leave>
            <router-view />
          </v-fade-transition>
        </v-row>
        <v-row> </v-row>
      </v-container>
      <Snackbar />
    </v-main>
    <TheFooter />
  </v-app>
</template>

<script>
// @ is an alias to /src
import config from "@/config.js";

import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import Snackbar from "@/components/Snackbar.vue";

import { mapState, mapGetters } from "vuex";

export default {
  components: {
    TheHeader,
    TheFooter,
    Snackbar,
  },
  computed: {
    ...mapGetters("cognito", ["isLoggedIn"]),
    ...mapState(["isCognitoReady"]),
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || "API til CVR data - cvr.dev";
    },
  },
  mounted: function () {
    var plausible = document.createElement("script");
    plausible.setAttribute("src", "https://plausible.io/js/plausible.hash.js");
    plausible.setAttribute("data-domain", config.PlausibleDataDomain);
    document.head.appendChild(plausible);
  },
};
</script>
import Vue from "vue";
import Vuex from "vuex";
import attachCognitoModule from "@vuetify/vuex-cognito-module";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    snackbar: {},
    isCognitoReady: false,
    authEmail: '',
  },

  mutations: {
    setSnackbar: (state, payload) => {
      state.snackbar = payload;
    },
    setIsCognitoReady: (state, val) => {
      state.isCognitoReady = val;
    },
    setAuthEmail: (state, email) => {
      state.authEmail = email;
    },
  },

  actions: {},

  modules: {},
});

attachCognitoModule(
  store,
  {
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_CLIENT_ID,
    region: process.env.VUE_APP_REGION,
  },
  "cognito"
);

store.dispatch("cognito/fetchSession")
  .catch(err => err)
  .finally(() => store.commit("setIsCognitoReady", true));

export default store;

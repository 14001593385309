import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "frontpage",
    component: () => import("@/views/public/Frontpage"),
    meta: {
      title: 'API til CVR data - cvr.dev'
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/public/TermsAndPrivacy"),
    meta: {
      title: 'Handelsbetingelser og privatlivspolitik - cvr.dev'
    },
  },
  {
    path: '/prices',
    name: 'prices',
    component: () => import('@/views/public/Prices')
  },
  {
    path: "/auth/signup",
    name: "signup",
    component: () => import("@/views/auth/SignUp"),
    meta: {
      title: 'Opret konto hos cvr.dev - API til data fra CVR'
    },
  },
  {
    path: "/auth/signin",
    name: "signin",
    component: () => import("@/views/auth/SignIn"),
  },
  {
    path: "/auth/signout",
    name: "signout",
    component: () => import("@/views/auth/SignOut"),
  },
  {
    path: "/auth/forgotpassword",
    name: "forgotpassword",
    component: () => import("@/views/auth/ForgotPassword"),
  },
  {
    path: "/auth/resetpassword",
    name: "resetpassword",
    component: () => import("@/views/auth/ResetPassword"),
  },
  {
    path: '/app/apikeys',
    name: 'apikeys',
    component: () => import('@/views/app/Profile')
  },
  {
    path: '/app/profile',
    name: 'profile',
    component: () => import('@/views/app/Profile')
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authRequired = to.path.startsWith('/app')

  if (authRequired) {
    try {
      await store.dispatch("cognito/fetchSession");
    } catch (err) {
      console.log(err);
    }
    const loggedIn = store.getters['cognito/isLoggedIn'];

    if (!loggedIn) {
      return next({ name: 'signin', query: { redirect: to.path } });
    }
  }

  next();
})

export default router;

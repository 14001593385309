import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

// Select colors here: https://lobotuerto.com/vuetify-color-theme-builder/
export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: "#343434",
        accent: "#01579B",
        secondary: "#FEFEFE",
        success: "#4CAF50",
        info: "#2196F3",
        warning: "#FB8C00",
        error: "#FF5252",
      },
      light: {
        primary: "#343434",
        accent: "#01579B",
        secondary: "#909090",
        success: "#4CAF50",
        info: "#2196F3",
        warning: "#FB8C00",
        error: "#FF5252",
      },
    },
  },
});

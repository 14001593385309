<template>
  <v-footer class="custom-footer">
    <v-col>
      <router-link :to="{ name: 'terms' }" v-slot="{ href, navigate }">
        <a rel="noopener noreferrer" :href="href" @click="navigate">Privatlivspolitik og betingelser</a>
      </router-link>
    </v-col>
    <v-col>
      <v-row justify="center">
        <a rel="noopener noreferrer" class="icon-spacer" target="_blank" href="mailto:kontakt@cvr.dev">
          kontakt@cvr.dev</a>
      </v-row>
      <v-row justify="center">cvr.dev API integrering ApS</v-row>
      <v-row justify="center">CVR 39952769</v-row>
    </v-col>
    <v-col>
      <v-row justify="end">
        <a rel="noopener noreferrer" class="icon-spacer no-decoration" target="_blank"
          href="https://github.com/cvr-dev">
          <v-icon medium>mdi-github</v-icon>
        </a>
        <a rel="noopener noreferrer" class="icon-spacer no-decoration" target="_blank"
          href="https://twitter.com/cvr_dev">
          <v-icon medium>mdi-twitter</v-icon>
        </a>
        <a rel="noopener noreferrer" target="_blank" class="no-decoration" href="https://www.facebook.com/api.cvr.dev/">
          <v-icon medium>mdi-facebook</v-icon>
        </a>
      </v-row>
    </v-col>
  </v-footer>
</template>

<script>
export default {};
</script>

<style lang="scss">
.icon-spacer {
  margin-right: 0.5rem;
}

.no-decoration {
  text-decoration: none;
}

.custom-footer {
  font-size: 0.75rem;
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
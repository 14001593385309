<template>
  <v-app-bar app color="primary">
    <router-link :to="{ name: 'frontpage' }" v-slot="{ href, navigate }">
      <a :href="href" @click="navigate">
        <v-toolbar-title id="app-title" color="white">cvr.dev</v-toolbar-title>
      </a>
    </router-link>
    <div id="app-bar-menu-items">
      <a rel="noopener noreferrer" target="_blank" :href="docsUrl">
        <div class="app-bar-menu-item">
          Dokumentation<v-icon small color="white">mdi-open-in-new</v-icon>
        </div>
      </a>
      <router-link :to="{ name: 'prices' }">
        <div class="app-bar-menu-item">Priser</div>
      </router-link>
    </div>

    <v-spacer></v-spacer>
    <div id="app-bar-menu-items">
      <router-link v-show="this.isLoggedIn" :to="{ name: 'profile' }">
        <div class="app-bar-menu-item">Profil</div>
      </router-link>
    </div>

    <router-link style="text-decoration: none" v-show="!this.isLoggedIn" :to="{ name: 'signup' }">
      <v-btn class="button-spacer" color="accent"> Opret konto </v-btn>
    </router-link>
    <router-link style="text-decoration: none" v-show="!this.isLoggedIn" :to="{ name: 'signin' }">
      <v-btn color="secondary"> Log ind </v-btn>
    </router-link>

    <router-link v-show="this.isLoggedIn" :to="{ name: 'signout' }">
      <v-btn v-show="this.isLoggedIn" icon color="white">
        <v-icon title="Log ud">mdi-logout</v-icon>
      </v-btn>
    </router-link>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import config from "@/config.js";

export default {
  computed: {
    ...mapGetters("cognito", ["isLoggedIn", "userAttributes"]),
  },
  data() {
    return {
      docsUrl: config.DocsUrl,
    };
  },
  methods: {
    signOut: function () {
      this.$store.dispatch("cognito/signOut").then(() => {
        this.$router.push({ name: "signin" });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#app-title {
  font-size: 2rem;
  font-weight: 700;
  color: #eee;
}

#app-bar-menu-items {
  margin-left: 2rem;
  height: 100%;
  display: flex;
}

.app-bar-menu-item {
  height: 100%;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  color: #eee;

  &:hover {
    background: #2a2a2a;
  }
}

.button-spacer {
  margin-right: 1rem;
}
</style>
